const ATTRIBUTES_BASE = ["beam-event"] as const;

export const ATTRIBUTES_PRODUCT = [
  "beam-brand",
  "beam-format-subtype",
  "beam-format-type",
  "beam-location",
  "beam-name",
  "beam-variant",
  "beam-position",
  "beam-category",
  "beam-coupon",
  "beam-price",
  "beam-product-id",
  "beam-quantity",
  "beam-text",
  "beam-sku",
  "beam-upc",
  "beam-outbound-url",
] as const;

export const ATTRIBUTES_ELEMENT = [
  "beam-element-type",
  "beam-html-id",
  "beam-location",
  "beam-name",
  "beam-position",
  "beam-text",
  "beam-variant",
  "beam-outbound-url",
] as const;

export const ALL_ATTRIBUTES = [
  ...ATTRIBUTES_BASE,
  ...ATTRIBUTES_PRODUCT,
  ...ATTRIBUTES_ELEMENT,
] as const;
