const transcriptLangMetadata = "x-amz-meta-transcribed_languages";

const fetchTranscriptLanguage = async (url: string) => {
  try {
    const response = await fetch(url, { method: "HEAD" });
    if (response.ok) {
      const transcriptLanguages = response.headers.get(transcriptLangMetadata);
      if (transcriptLanguages) {
        return JSON.parse(transcriptLanguages);
      }
    }
    return null;
  } catch (error) {
    console.error("Error fetching transcript data:", error);
    window.newrelic.noticeError(error as Error);
    return null;
  }
};

export default fetchTranscriptLanguage;
