import { BeamableComponentAttributes } from "@/components/CohesionReady/eventing/types";
import { ALL_ATTRIBUTES } from "@/components/CohesionReady/eventing/constants";

/**
 * Get a unique list of all Beamable Attribute names.
 */
const getAllBeamableAttributes = (): string[] => {
  const array = ALL_ATTRIBUTES;
  return array.filter((item, index) => array.indexOf(item) === index);
};

/**
 * Process incomming properties and extract the possible beamable properties.
 * @param props Incoming props from component
 */
export function fetchBeamableProperties<P extends BeamableComponentAttributes>(
  props: P,
): BeamableComponentAttributes | Record<string, string> {
  const beamable: Record<string, string> = {};
  getAllBeamableAttributes().forEach((key) => {
    const val = (props as unknown as Record<string, string>)[key];
    if (val) beamable[key] = val;
  });
  return beamable as BeamableComponentAttributes;
}
