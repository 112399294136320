const getNocookieUrl = (videoId: string): string => {
  const origin = global.window && window.location.origin;

  return `https://www.youtube-nocookie.com/embed/${videoId}?controls=2&modestbranding=1&rel=0&loop=0&hl=en&enablejsapi=1&origin=${origin}&widgetid=1&autoplay=1`;
};

const convertToNocookieUrl = (url: string): string => {
  // Regular expression to match YouTube video URLs
  const youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)(.*)$/;

  // Check if the input URL matches the YouTube video URL pattern
  const match = RegExp(youtubeRegex).exec(url);

  if (match) {
    // Extract the video ID from the match
    const videoId = match[4];

    // Construct the YouTube-nocookie URL
    return getNocookieUrl(videoId);
  }

  // If the input URL doesn't match the pattern, return the original URL
  return url;
};

export default convertToNocookieUrl;
