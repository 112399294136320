"use client";
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "plyr/dist/plyr.css";
import getHlsVideoId from "../utils/getHlsVideoId";
import fetchTranscriptLanguage from "../utils/fetchTranscriptLanguage";
import getTranscriptionUrl from "../utils/getTranscriptionUrl";
import useSWR from "swr";
import "./plyr_styles.css";

const HlsVideoPlayer = ({
  videoClassName,
  videoUrl,
}: {
  videoClassName?: string;
  videoUrl: string;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [plyr, setPlyr] = useState<Plyr | null>(null);
  const { data: transcriptData } = useSWR(videoUrl, fetchTranscriptLanguage);
  if (transcriptData) {
    Object.keys(transcriptData).forEach((languageCode) => {
      const trackElement = document.createElement("track");
      trackElement.kind = "captions";
      trackElement.label = transcriptData[languageCode];
      trackElement.srclang = languageCode;
      trackElement.src = getTranscriptionUrl(
        getHlsVideoId(videoUrl),
        languageCode,
      );
      if (videoRef.current) {
        videoRef.current.appendChild(trackElement);
      }
    });
  }

  const setUpVideoPlayer = () => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      if (videoRef.current) {
        hls.attachMedia(videoRef.current);
      }

      plyr?.on("languagechange", () => {
        setTimeout(() => {
          hls.subtitleTrack = plyr.currentTrack;
        }, 50);
      });
    }
  };

  // load Plyr script
  useEffect(() => {
    if (videoRef.current) {
      // Dynamic import is used because plyr library makes use of DOM on import, so it breaks SSR
      import("plyr").then((p) => {
        setPlyr(
          new p.default(
            videoRef.current as string | HTMLElement | NodeList | HTMLElement[],
            {
              settings: ["captions"],
              controls: [
                "play",
                "mute",
                "progress",
                "captions",
                "pip",
                "fullscreen",
                "settings",
              ],
              autoplay: true,
              captions: { active: false, update: true },
            },
          ),
        );
      });
    }

    return () => {
      if (plyr) {
        plyr.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (plyr) {
      setUpVideoPlayer();
    }
  }, [plyr]);

  return (
    <video
      controls
      ref={videoRef}
      crossOrigin="use-credentials"
      className={videoClassName}
      autoPlay
    >
      <source src={videoUrl} type="application/x-mpegURL" />
    </video>
  );
};

export default HlsVideoPlayer;
