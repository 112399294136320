"use client";
import React, { useState, useEffect } from "react";
import { getUser, showAdmin, User, logout } from "@/lib/wordpress/auth";
import { useRouter } from "next/navigation";
import setUserLocation from "@/lib/location/setUserLocation";
import getUserLocation from "@/lib/location/getUserLocationFromClient";

interface AdminToolbarProps {
  searchParams?: Record<string, any>;
  postId?: string | number;
  cmsUrl: string;
}

const AdminToolbar = ({ searchParams, postId, cmsUrl }: AdminToolbarProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const router = useRouter();
  const wordpressAdminUrl = `${cmsUrl}/wp-admin`;
  const wordpressCreateUrl = `${wordpressAdminUrl}/post-new.php?post_type=page`;
  const wordpressEditUrl = `${wordpressAdminUrl}/post.php?post=${postId}&action=edit`;
  useEffect(() => {
    // getUser(searchParams?.code).then((data) => {
    //   setUser(data);
    // });
    // showAdmin().then((data) => {
    //   setIsLoggedIn(data);
    // });
    if (!getUserLocation()) {
      setUserLocation();
    }
  }, []);

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
    setUser(null);
    const url = window.location.href.split("?")[0];
    router.push(url);
  };

  return (
    <>
      {isLoggedIn ? (
        <div className="not-prose fixed bg-gray-700 text-white text-sm flex justify-between sticky top-0 z-[9999]">
          <div className="flex gap-2">
            <a
              className="text-white text-xs p-2 flex justify-center items-center gap-2 w-fit hover:bg-gray-600"
              href={wordpressAdminUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              edX.org
            </a>
            <a
              className="text-white text-xs p-2 flex justify-center items-center gap-2 w-fit hover:bg-gray-600"
              href={wordpressCreateUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Create Page
            </a>
            {wordpressEditUrl && (
              <a
                className="text-white text-xs p-2 flex justify-center items-center gap-2 w-fit hover:bg-gray-600"
                href={wordpressEditUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit
              </a>
            )}
          </div>
          <div>
            <span className="mr-3">Howdy, {user?.name}</span>
            <button
              className="bg-gray-800 text-white text-xs px-4 py-2"
              onClick={handleLogout}
            >
              Close Toolbar
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AdminToolbar;
