import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// Formats rich text from the CMS Contentful
export const formatRichText = (str: string, options = {}) => {
  if (str) {
    const content = JSON.parse(str);
    return documentToReactComponents(JSON.parse(content), options);
  }

  return null;
};

export const uppercaseBreadcrumbAbbreviations = (
  breadcrumbs: string[],
): string[] => {
  if (breadcrumbs?.length > 0) {
    const abbreviationMapping: Record<string, string> = {
      mba: "MBA",
    };

    return breadcrumbs.map((str) => {
      return str.replace(/\b[a-z]{2,}\b/g, (match) => {
        return abbreviationMapping[match.toLowerCase()] || match;
      });
    });
  }
  return [];
};

// Convert and format prices based on currency, conversion rate, and locale
export const formatPrice = (
  price: string | number,
  currency: {
    code: string;
    rate: number;
    symbol: string;
  },
  locale: string = "en-US",
  showCode: boolean = true,
  formatOptions: Intl.NumberFormatOptions = {},
) => {
  const { code = "USD", rate = 1.0 } = currency;
  let newPrice = typeof price === "string" ? parseFloat(price) : price;
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: code,
    currencyDisplay: "symbol",
    ...formatOptions,
  };

  if (Number.isNaN(newPrice)) {
    throw Error(
      `Price is not a number! price: ${price} - newPrice: ${newPrice}`,
    );
  }

  if (rate !== 1.0) {
    newPrice = Math.round(newPrice * rate);
  }

  if (newPrice % 1 === 0) {
    // Don't include fractional part for whole-numbered prices
    options.minimumFractionDigits = 0; // HACK: Have to set min or max throws out-of-range
    options.maximumFractionDigits = 0;
  }

  const formatter = new Intl.NumberFormat(locale, options);
  let formattedPrice = formatter.format(newPrice);

  if (/^[$\d,.\s]+$/.test(formattedPrice)) {
    // If the formatted price has only potentially ambiguous currency signs ($), digits,
    // separators (,.), and/or spaces, add the code for clarity
    formattedPrice = `${formattedPrice} ${code}`;
  }

  return formattedPrice;
};
