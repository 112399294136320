"use client";

import {
  CTAProps,
  renderCTAMarkup,
} from "@/components/CTA/variants/CtaLink/shared";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import { getCorrelationID, hyphenateForTagular } from "@/utils/tagular/helpers";
import makeEvent from "@/hooks/eventing/makeEvent";
import { EVENTS } from "@/hooks/eventing/types/events";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";

export default function ClientCTALink(props: CTAProps) {
  const webElement: ElementViewed["webElement"] = {
    elementType: props.elementType ?? props.element_type ?? ElementType.Button,
    htmlId: props.html_id,
    location: props.location as string,
    position: "1",
    text: props.label,
    name: props.name,
    variant: props.tagular_variant,
  };

  const { elementViewed, elementClicked } = useElementEvents({ webElement });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const onClick = () => {
    elementClicked({ outboundUrl: props.url });

    if (props.conversionTrackingInfo) {
      const conversionEvent: ConversionTracked = {
        correlation: { id: getCorrelationID() },
        metadata: {
          action: "Started",
          category: "Enrollment",
          timestamp: new Date().toISOString(),
          productList: [
            {
              brand: hyphenateForTagular(props.conversionTrackingInfo.brand),
              category: hyphenateForTagular(
                props.conversionTrackingInfo.category,
              ),
              variant: translateVariant(props.conversionTrackingInfo.variant),
              name: props.conversionTrackingInfo.productName || "unknown",
            },
          ],
        },
      };
      const event = makeEvent<ConversionTracked, EVENTS>(conversionEvent);
      event<ConversionTracked>(EVENTS.ConversionTracked)();
    }
  };

  return renderCTAMarkup({ ...props, onClick, ref });
}
