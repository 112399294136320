"use client";
import React from "react";

const YouTubeVideoPlayer = ({
  videoClassName,
  title,
  videoUrl,
}: {
  videoClassName?: string;
  title: string;
  videoUrl: string;
}) => (
  <div className="w-full aspect-video">
    <iframe
      title={title}
      className={videoClassName}
      src={videoUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  </div>
);

export default YouTubeVideoPlayer;
